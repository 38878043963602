var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"flexDK"},[_c('v-toolbar-title',[_vm._v("Agendamiento")]),_c('v-btn',{staticStyle:{"padding":"0","margin-left":"2rem","width":"108px"},attrs:{"color":"primary","loading":_vm.loadingAppointments},on:{"click":_vm.search}},[_vm._v(" Buscar ")]),_c('v-btn',{staticStyle:{"padding":"0","margin-left":"2rem","width":"108px"},attrs:{"color":"gray"},on:{"click":_vm.clear}},[_vm._v(" Limpiar ")]),_c('v-btn',{staticStyle:{"padding":"0","margin-left":"2rem","width":"108px"},attrs:{"color":"gray"},on:{"click":_vm.exportData}},[_vm._v(" Descargar ")])],1)]),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},model:{value:(_vm.showModalExport),callback:function ($$v) {_vm.showModalExport=$$v},expression:"showModalExport"}},[[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Selecciona el rango de fechas para exportar")]),_c('v-card-text',[_c('v-dialog',{ref:"dialogExport",staticClass:"inputDK",attrs:{"return-value":_vm.dateExport,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.dateExport=$event},"update:return-value":function($event){_vm.dateExport=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeExportText),callback:function ($$v) {_vm.dateRangeExportText=$$v},expression:"dateRangeExportText"}},'v-text-field',attrs,false),on))]}},{key:"selection",fn:function(ref){
var data = ref.data;
return [_c('v-chip',[_c('span',[_vm._v(_vm._s(data)+" ")])])]}}]),model:{value:(_vm.modalExportData),callback:function ($$v) {_vm.modalExportData=$$v},expression:"modalExportData"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dateExport),callback:function ($$v) {_vm.dateExport=$$v},expression:"dateExport"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalExportData = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogExport.save(_vm.dateExport)}}},[_vm._v(" Aceptar ")])],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","dark":"","loading":_vm.loading},on:{"click":_vm.exportData}},[_vm._v("Descargar")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showModalExport = false}}},[_vm._v("Cerrar")])],1)],1)]],2)],1),_c('v-card-text',{staticClass:"white lighten-4 VCardText"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"VcolDK",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-dialog',{ref:"dialogInit",staticClass:"inputDK",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Rango de fechas","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}},{key:"selection",fn:function(ref){
var data = ref.data;
return [_c('v-chip',[_c('span',[_vm._v(_vm._s(data)+" ")])])]}}]),model:{value:(_vm.modalInit),callback:function ($$v) {_vm.modalInit=$$v},expression:"modalInit"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalInit = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogInit.save(_vm.date)}}},[_vm._v(" Aceptar ")])],1)],1)],1),_c('v-col',{staticClass:"VcolDK",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{staticClass:"inputDK",attrs:{"label":"DNI"},model:{value:(_vm.dni),callback:function ($$v) {_vm.dni=$$v},expression:"dni"}})],1),_c('v-col',{staticClass:"VcolDK",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{staticClass:"inputDK",attrs:{"label":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{staticClass:"VcolDK",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-select',{staticClass:"inputDK",attrs:{"items":_vm.pointsFilter,"item-text":"name","item-value":"cost_center","menu-props":{ maxHeight: '400' },"label":"Punto(selección múltiple)","multiple":"","persistent-hint":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.searchPoint.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Seleccionar Todo ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0 && _vm.searchPoint.length == 1)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name)+" ")])]):_vm._e(),(index === 0 && _vm.pointsFilter.length == _vm.searchPoint.length)?_c('span',[_c('span',[_vm._v("Seleccionadas: todas ")])]):_vm._e(),(
                index === 0 &&
                _vm.searchPoint.length > 1 &&
                _vm.pointsFilter.length != _vm.searchPoint.length
              )?_c('span',[_c('span',[_vm._v("Seleccionadas: "+_vm._s(_vm.searchPoint.length)+" ")])]):_vm._e()]}}]),model:{value:(_vm.searchPoint),callback:function ($$v) {_vm.searchPoint=$$v},expression:"searchPoint"}})],1),_c('v-col',{staticClass:"VcolDK",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-select',{staticClass:"inputDK",attrs:{"items":_vm.statesList,"menu-props":{ maxHeight: '400' },"label":"Estado(selección múltiple)","multiple":"","persistent-hint":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggleStatus}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.states.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.iconStatus)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Seleccionar Todo ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index === 0 && _vm.states.length == 1)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name)+" ")])]):_vm._e(),(index === 0 && _vm.states.length == _vm.states.length)?_c('span',[_c('span',[_vm._v("Seleccionadas: todas ")])]):_vm._e(),(
                index === 0 &&
                _vm.states.length > 1 &&
                _vm.states.length != _vm.states.length
              )?_c('span',[_c('span',[_vm._v("Seleccionadas: "+_vm._s(_vm.states.length)+" ")])]):_vm._e()]}}]),model:{value:(_vm.states),callback:function ($$v) {_vm.states=$$v},expression:"states"}})],1)],1)],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","value":"15"}}):_vm._e(),(_vm.appointments.length)?_c('visor-dates',{attrs:{"appointments":_vm.appointments,"nameSelected":_vm.nameSelected,"selectAppointment":_vm.selectAppointment,"environmentData":_vm.environmentData,"date":_vm.date,"pagination":_vm.pagination,"initFixed":_vm.initFixed,"limitFixed":_vm.limitFixed,"init":_vm.init,"limit":_vm.limit,"page":_vm.page},on:{"cancelEvent":_vm.cancelAppointment,"preload":_vm.preload,"searchAppointments":_vm.search}},[_c('v-expand-transition',[_c('div',_vm._l((_vm.items),function(item,index){return _c('v-card-text',{key:index,staticClass:"grey lighten-4"},[_c('v-sheet',{staticClass:"mx-auto",attrs:{"max-width":"890","height":"498"}},[(item.name.split('.')[1] != 'pdf')?_c('img',{attrs:{"src":item.value,"alt":"Image"}}):_c('embed',{attrs:{"src":item.value,"width":"500","height":"375","type":"application/pdf"}}),_c('a',{on:{"click":function($event){return _vm.download(item)}}},[_vm._v("Descargar")])])],1)}),1)])],1):_vm._e(),(!_vm.loading && !_vm.appointments.length)?_c('p',{staticStyle:{"padding":"1rem"}},[_vm._v(" No hay resultados ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.appointments.length > 1),expression:"appointments.length > 1"}],staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.appointments.length / this.pagination)},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('cancel-appointments',{ref:"cancelAppointments",on:{"searchAppointments":_vm.search}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }