<template>
  <div class="VisorDate">
    <div class="VisorGrid" :class="showPDF ? 'VisorPdfGrid' : ''">
      <v-progress-linear
        v-if="loading"
        indeterminate
        value="15"
      ></v-progress-linear>
      <header class="VisorGrid__Header">
        <!-- <div class="px-2"></div> -->
        <div class="px-2">Id Agendamiento</div>
        <div class="px-2">Fecha y Hora</div>
        <div class="px-2">EPS</div>
        <div class="px-2">Ciudad/Municipio</div>
        <div class="px-2">Tipo de Documento</div>
        <div class="px-2">Número de Documento</div>
        <div class="px-2">Nombre Beneficiario</div>
        <div class="px-2">Número de Formula (S)</div>
        <div class="px-2">Email Beneficiario</div>
        <div class="px-2">Teléfono Beneficiario</div>
        <div class="px-2">Dirección Beneficiario</div>
        <div class="px-2">Ceco de Agendamiento</div>
        <div class="px-2">Nombre Ceco</div>
        <div class="px-2">Direccion ceco</div>
        <div class="px-2">Duración Cita</div>
        <div class="px-2">Cancelación</div>
        <div class="px-2">Fecha de cancelación</div>
        <div class="px-2">Usuario que Cancela</div>
        <div class="px-2">Check Autorizacion de Datos</div>
        <div class="px-2">Fecha Autorizacion de Datos</div>
        <div class="px-2">Hora Autorizacion de Datos</div>
        <div class="px-2">Efectivo</div>
        <div class="px-2">Razón</div>
      </header>
      <div class="BodyG">
        <ul
          v-for="(item, index) in appointmentsByDate"
          :key="index"
          :class="`VisorGrid__Body VBC${index}`"
        >
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('codeTable' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'codeTable' + index">{{ item.code }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.code
            }}</span>
          </li>
          <li class="VisorGrid__Item" :class="` item_visor_${item.id} `">
            <span class="tooltip-box">
              <span
                @click="() => copyText('startAtTable' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'startAtTable' + index">{{
                item.startAt | formatDate
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.startAt | formatDate
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('pointReason' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <!-- <span :id="'pointReason' + index">{{ getEps(getMyEps(item.reason.point)).name }}</span> -->
              <span :id="'pointReason' + index">{{
                getEps(item.reason.eps).name
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              getEps(item.reason.eps).name
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('cityPoint' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'cityPoint' + index">{{
                item.dispensingPoint.city
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.dispensingPoint.city
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('documentTypeFullData' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'documentTypeFullData' + index">{{
                item.customer.full_data.documentType
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.customer.full_data.documentType
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('customerDni' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'customerDni' + index">{{ item.customer.dni }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.customer.dni
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('customerFirstName' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'customerFirstName' + index">{{
                item.customer.firstName
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.customer.firstName
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('customerReason' + index)"
                class="copyBtn"
                v-if="item.reason"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'customerReason' + index" v-if="item.reason">{{
                item.reason && item.reason.formulas
                  ? item.reason.formulas
                      .replace("9999999999999", "No se especificó fórmula")
                      .split(";")
                      .join("/")
                  : "-"
              }}</span>
              <span v-else :id="'customerReason' + index">-</span>
            </span>
            <span
              v-if="item.reason"
              @click="() => handleSelectItem(index, item)"
              >{{
                item.reason && item.reason.formulas
                  ? item.reason.formulas
                      .replace("9999999999999", "No se especificó fórmula")
                      .split(";")
                      .join("/")
                  : "-"
              }}</span
            >
            <span v-else @click="() => handleSelectItem(index, item)">-</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('customerEmail' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'customerEmail' + index">{{
                item.customer.email
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.customer.email
            }}</span>
          </li>
          <!-- <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span>{{ item.customer.firstName }}</span>
          </li> -->
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('customerPhone' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'customerPhone' + index">{{
                item.customer.full_data.phone
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.customer.full_data.phone
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('customerAddress' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'customerAddress' + index">{{
                item.customer.full_data.address
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.customer.full_data.address
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('costCenter' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'costCenter' + index">{{
                item.dispensingPoint.cost_center || "-"
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.dispensingPoint.cost_center || "-"
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('dispensingPointName' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'dispensingPointName' + index">{{
                item.dispensingPoint.name || "-"
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.dispensingPoint.name || "-"
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('dispensingPointAddress' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'dispensingPointAddress' + index">{{
                item.dispensingPoint.address || "-"
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.dispensingPoint.address || "-"
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('differenceBetweenStartEnd' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'differenceBetweenStartEnd' + index">{{
                getDifference(item.startAt, item.endAt) || "-"
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              getDifference(item.startAt, item.endAt) || "-"
            }}</span>
          </li>

          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('statusTable' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'statusTable' + index">{{
                item.status == "CANCELED" ? "SI" : "NO" || "-"
              }}</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">{{
              item.status == "CANCELED" ? "SI" : "NO" || "-"
            }}</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('timestampTable' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span
                :id="'timestampTable' + index"
                v-if="
                  item.actions &&
                  item.actions.filter(
                    (a) =>
                      a.action == 'Click: Cancelación' ||
                      a.action.includes('Silver: Cancelación')
                  ).length > 0 &&
                  item.status == 'CANCELED'
                "
                >{{
                  item.actions.filter(
                    (a) =>
                      a.action == "Click: Cancelación" ||
                      a.action.includes("Silver: Cancelación")
                  )[0].timestamp || "-" | formatUnixDate
                }}</span
              >
              <span v-else :id="'timestampTable' + index">-</span>
            </span>
            <span
              @click="() => handleSelectItem(index, item)"
              v-if="
                item.actions &&
                item.actions.filter(
                  (a) =>
                    a.action == 'Click: Cancelación' ||
                    a.action.includes('Silver: Cancelación')
                ).length > 0 &&
                item.status == 'CANCELED'
              "
              >{{
                item.actions.filter(
                  (a) =>
                    a.action == "Click: Cancelación" ||
                    a.action.includes("Silver: Cancelación")
                )[0].timestamp || "-" | formatUnixDate
              }}</span
            >
            <span v-else @click="() => handleSelectItem(index, item)">-</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('actionsTableDocs' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span
                :id="'actionsTableDocs' + index"
                v-if="
                  item.actions &&
                  item.actions.filter(
                    (a) =>
                      a.action == 'Click: Cancelación' ||
                      a.action.includes('Silver: Cancelación')
                  ).length > 0 &&
                  item.status == 'CANCELED'
                "
                >{{
                  item.actions.filter(
                    (a) =>
                      a.action == "Click: Cancelación" ||
                      a.action.includes("Silver: Cancelación")
                  )[0].document || "-"
                }}</span
              >
              <span v-else :id="'actionsTableDocs' + index">-</span>
            </span>
            <span
              @click="() => handleSelectItem(index, item)"
              v-if="
                item.actions &&
                item.actions.filter(
                  (a) =>
                    a.action == 'Click: Cancelación' ||
                    a.action.includes('Silver: Cancelación')
                ).length > 0 &&
                item.status == 'CANCELED'
              "
              >{{
                item.actions.filter(
                  (a) =>
                    a.action == "Click: Cancelación" ||
                    a.action.includes("Silver: Cancelación")
                )[0].document || "-"
              }}</span
            >
            <span v-else @click="() => handleSelectItem(index, item)">-</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span @click="() => copyText('SI' + index)" class="copyBtn">
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span :id="'SI' + index">SI</span>
            </span>
            <span @click="() => handleSelectItem(index, item)">SI</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span @click="() => copyText('accept' + index)" class="copyBtn">
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span
                :id="'accept' + index"
                v-if="
                  item.actions &&
                  item.actions.filter((a) => a.action == 'Click: Aceptación')
                    .length > 0
                "
                >{{
                  item.actions.filter((a) => a.action == "Click: Aceptación")[0]
                    .timestamp || "-" | formatOnlyDate
                }}</span
              >
              <span v-else :id="'accept' + index">-</span>
            </span>
            <span
              @click="() => handleSelectItem(index, item)"
              v-if="
                item.actions &&
                item.actions.filter((a) => a.action == 'Click: Aceptación')
                  .length > 0
              "
              >{{
                item.actions.filter((a) => a.action == "Click: Aceptación")[0]
                  .timestamp || "-" | formatOnlyDate
              }}</span
            >
            <span v-else @click="() => handleSelectItem(index, item)">-</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('formatUnix' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span
                :id="'formatUnix' + index"
                v-if="
                  item.actions &&
                  item.actions.filter((a) => a.action == 'Click: Aceptación')
                    .length > 0
                "
                >{{
                  item.actions.filter((a) => a.action == "Click: Aceptación")[0]
                    .timestamp || "-" | formatOnlyHour
                }}</span
              >
              <span v-else :id="'formatUnix' + index">-</span>
            </span>
            <span
              v-if="
                item.actions &&
                item.actions.filter((a) => a.action == 'Click: Aceptación')
                  .length > 0
              "
              @click="() => handleSelectItem(index, item)"
              >{{
                item.actions.filter((a) => a.action == "Click: Aceptación")[0]
                  .timestamp || "-" | formatOnlyHour
              }}</span
            >
            <span v-else @click="() => handleSelectItem(index, item)">-</span>
          </li>

          <!-- extra field -->
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('efectiveAg' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span
                :id="'efectiveAg' + index"
                v-if="
                  item.actions &&
                  item.actions.filter((a) => a.action == 'Silver: ExtraFields')
                    .length > 0
                "
                >{{
                  item.actions.filter(
                    (a) => a.action == "Silver: ExtraFields"
                  )[0].efective || "-"
                }}</span
              >
              <span v-else :id="'efectiveAg' + index">-</span>
            </span>
            <span
              @click="() => handleSelectItem(index, item)"
              v-if="
                item.actions &&
                item.actions.filter((a) => a.action == 'Silver: ExtraFields')
                  .length > 0
              "
              >{{
                item.actions.filter((a) => a.action == "Silver: ExtraFields")[0]
                  .efective || "-"
              }}</span
            >
            <span v-else @click="() => handleSelectItem(index, item)">-</span>
          </li>
          <li
            class="VisorGrid__Item active--item"
            :class="` item_visor_${item.id} `"
          >
            <span class="tooltip-box">
              <span
                @click="() => copyText('reasonCausable' + index)"
                class="copyBtn"
              >
                <v-icon small dense>mdi-content-copy</v-icon>
              </span>
              <span
                :id="'reasonCausable' + index"
                v-if="
                  item.actions &&
                  item.actions.filter((a) => a.action == 'Silver: ExtraFields')
                    .length > 0
                "
                >{{
                  item.actions.filter(
                    (a) => a.action == "Silver: ExtraFields"
                  )[0].reason || "-"
                }}</span
              >
              <span v-else :id="'reasonCausable' + index">-</span>
            </span>
            <span
              @click="() => handleSelectItem(index, item)"
              v-if="
                item.actions &&
                item.actions.filter((a) => a.action == 'Silver: ExtraFields')
                  .length > 0
              "
              >{{
                item.actions.filter((a) => a.action == "Silver: ExtraFields")[0]
                  .reason || "-"
              }}</span
            >
            <span v-else @click="() => handleSelectItem(index, item)">-</span>
          </li>
          <!-- ------ -->
        </ul>
      </div>
    </div>
    <div
      class="VisorPdf"
      :class="changePDFClassState ? 'VisorPdfExpanded' : ''"
      v-if="showPDF"
    >
      <div class="Close">
        <button @click="showPDF = false">
          <v-icon small dense>mdi-close</v-icon>
        </button>
      </div>
      <button class="ButtonPDF" @click="changePDFClass">
        <v-icon small dense v-if="!changePDFClassState"
          >mdi-arrow-expand-left</v-icon
        >
        <v-icon small dense v-else>mdi-arrow-expand-right</v-icon>
      </button>
      <div class="Dropdowns">
        <div class="AgendamientoEfectivo" v-if="selectedItem.status != 'CANCELED'">
          <v-select
            class="inputDK"
            v-model="dropdown"
            :items="efectiveAgArray"
            item-text="name"
            item-value="cost_center"
            :menu-props="{ maxHeight: '400' }"
            label="Agendamiento efectivo"
            persistent-hint
          >
            <!-- <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggleEfectiveAgArray">
                <v-list-item-action>
                  <v-icon
                    :color="dropdown.length > 0 ? 'indigo darken-4' : ''"
                  >
                    {{ iconEfectiveAgArray }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Seleccionar Todo </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template> -->
            <!-- Add a tile with Select All as Lalbel and binded on a method that add or remove all items -->
            <template v-slot:selection="{ item }">
              <!-- <v-chip v-if="index === 0 && dropdown.length == 1">
                <span>{{ item }} </span>
              </v-chip> -->
              <v-chip>
                <span>{{ item }}</span>
              </v-chip>
              <!-- <span
                v-if="index === 0 && causalArray.length == dropdown.length"
              >
                <span>Seleccionadas: todas </span>
              </span>
              <span
                v-if="
                  index === 0 &&
                  dropdown.length > 1 &&
                  causalArray.length != dropdown.length
                "
              >
                <span>Seleccionadas: {{ dropdown.length }} </span>
              </span> -->
            </template>
          </v-select>
        </div>
        <div class="Causal" v-if="dropdown === 'NO'">
          <v-select
            class="inputDK"
            v-model="dropdownCausal"
            :items="causalArray"
            item-text="name"
            item-value="cost_center"
            :menu-props="{ maxHeight: '400' }"
            label="Causal no entrega"
            multiple
            persistent-hint
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="toggleStatusCausalArray"
              >
                <v-list-item-action>
                  <v-icon
                    :color="dropdownCausal.length > 0 ? 'indigo darken-4' : ''"
                  >
                    {{ iconCausalArray }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Seleccionar Todo </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <!-- Add a tile with Select All as Lalbel and binded on a method that add or remove all items -->
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0 && dropdownCausal.length == 1">
                <span>{{ item.name ? item.name : item }}</span>
              </v-chip>
              <span
                v-if="
                  index === 0 && causalArray.length == dropdownCausal.length
                "
              >
                <span>Seleccionadas: todas </span>
              </span>
              <span
                v-if="
                  index === 0 &&
                  dropdownCausal.length > 1 &&
                  causalArray.length != dropdownCausal.length
                "
              >
                <span>Seleccionadas: {{ dropdownCausal.length }} </span>
              </span>
            </template>
          </v-select>
        </div>
        <div class="block">
          <v-btn
            :disabled="!dropdown || (dropdown == 'NO' && (!dropdownCausal || (dropdownCausal && dropdownCausal.length < 1)))"
            @click="saveExtraFields(dropdown)"
            small
            :loading="loading"
            color="primary"
            >Guardar</v-btn
          >
        </div>
      </div>
      <div class="ContainerText">
        <p :class="changePDFClassState ? 'marginToAllwidth' : 'AllWidth'">
          <span>
            <span>Fecha y Hora: </span>
            <span :id="`${selectedItem.startAt}`">{{
              getDate(selectedItem.startAt)
            }}</span>
          </span>
          <span @click="() => copyText(`${selectedItem.startAt}`)">
            <v-icon small dense>mdi-content-copy</v-icon>
          </span>
        </p>
        <p :class="changePDFClassState ? 'marginToAllwidth' : 'AllWidth'">
          <span>
            <span>Número de Documento: </span>
            <span :id="`${selectedItem.customer.dni}`">{{
              selectedItem.customer.dni
            }}</span>
          </span>
          <span @click="() => copyText(`${selectedItem.customer.dni}`)">
            <v-icon small dense>mdi-content-copy</v-icon>
          </span>
        </p>
        <p :class="changePDFClassState ? 'marginToAllwidth' : 'AllWidth'">
          <span>
            <span>Nombre benefeciario: </span>
            <span :id="`${selectedItem.customer.firstName}`">{{
              selectedItem.customer.firstName
            }}</span>
          </span>
          <span @click="() => copyText(`${selectedItem.customer.firstName}`)">
            <v-icon small dense>mdi-content-copy</v-icon>
          </span>
        </p>
        <p :class="changePDFClassState ? 'marginToAllwidth' : 'AllWidth'">
          <span>
            <span>Email: </span>
            <span :id="`${selectedItem.customer.email}`">{{
              selectedItem.customer.email
            }}</span>
          </span>
          <span @click="() => copyText(`${selectedItem.customer.email}`)">
            <v-icon small dense>mdi-content-copy</v-icon>
          </span>
        </p>
        <p :class="changePDFClassState ? 'marginToAllwidth' : 'AllWidth'">
          <span>
            <span>Teléfono benefeciario: </span>
            <span :id="`${selectedItem.customer.phone}`">{{
              selectedItem.customer.phone || "-"
            }}</span>
          </span>
          <span @click="() => copyText(`${selectedItem.customer.phone}`)">
            <v-icon small dense>mdi-content-copy</v-icon>
          </span>
        </p>
        <div class="block" v-if="selectedItem.status != 'CANCELED' && availableDate(selectedItem.startAt)">
          <v-btn
            small
            color="error"
            @click="sendCancelAppointment(selectedItem, 'cancel')"
            >Cancelar</v-btn
          >
        </div>
        <div class="block" v-if="selectedItem.status == 'CANCELED' && availableDate(selectedItem.startAt)">
          <v-btn
            @click="sendCancelAppointment(selectedItem, 'restart')"
            small
            color="primary"
            >Restaurar cita cancelada</v-btn
          >
        </div>
      </div>
      <div class="ContainerText" v-if="selectedItem.reason">
        <div
          v-for="(item, index) in toArrayForm(selectedItem.reason.formulas)"
          :key="index"
          class=""
        >
          <v-btn @click="() => handleClickFormBtn(item, selectedItem)">
            <span :id="`${item}_${index}`">
              {{
                item
                  ? item.replace("9999999999999", "No se especificó fórmula")
                  : ""
              }}
            </span>
          </v-btn>
          <span @click="() => copyText(`${item}_${index}`)">
            <v-icon small dense>mdi-content-copy</v-icon>
          </span>
        </div>
      </div>
      <div class="btnStateC" v-if="btnState && items">
        <div class="VideoDK">
          <v-expand-transition>
            <div>
              <v-card-text
                v-for="(it, index) in items.filter(
                  (i) => btnState == i[0].formula
                )"
                :key="index"
              >
                <v-sheet max-width="890" class="mx-auto dkShee">
                  <div v-for="(file, i) in it" :key="i" class="ItemSeparete">
                    <img
                      :src="'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif'"
                      :id="`${file.name}_image`"
                      v-if="loading"
                    />
                    <img
                      :src="file.value"
                      :id="`${file.name}_image`"
                      v-if="file.name.split('.')[1] != 'pdf' && !loading"
                    />
                    <div v-else>
                      <pdf
                        v-if="!numPages[file.id]"
                        @num-pages="getPagesPdf($event, file.id)"
                        :src="file.value"
                        :id="`${file.name}_image`"
                      ></pdf>
                      <pdf
                        v-else
                        v-for="(i, k) in numPages[file.id]"
                        :key="k"
                        @page-loaded="getPdfLoaded"
                        :page="i"
                        :src="file.value"
                        :id="`${file.name}_image`"
                      ></pdf>
                    </div>

                    <div class="flex-dk">
                      <a
                        @click="() => download(file)"
                        class="btnIcon"
                        target="_blank"
                      >
                        <v-icon>mdi-download</v-icon>
                      </a>
                      <button
                        class="btnIcon"
                        @click="() => handleFullscreen(`${file.name}_image`)"
                      >
                        <v-icon>mdi-fullscreen</v-icon>
                      </button>
                    </div>
                  </div>
                  <!-- <a @click="download(it)">Descargar</a> -->
                </v-sheet>
              </v-card-text>
            </div>
          </v-expand-transition>
        </div>
      </div>
      <!-- <slot /> -->
    </div>

    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import axios from "axios";
var AWS = require("aws-sdk");
let s3, bucketName;
import pdf from "vue-pdf";
moment.locale("es");

export default {
  name: "VisorDate",
  components: {
    pdf,
  },
  data() {
    return {
      showPDF: false,
      changePDFClassState: false,
      selectedItem: null,

      items: [],
      btnState: null,
      dropdown: false,
      dropdownCausal: false,
      numPages: {},
      existPdf: false, // to end loading
      loading: false,
      causalArray: [],
      efectiveAgArray: ["SI", "NO"],

      text: "",
      snackbar: false,
      sendData: 0,
      sending: null,
    };
  },
  props: [
    "appointments",
    "nameSelected",
    "selectAppointment",
    "environmentData",
    "date",
    "pagination",
    "initFixed",
    "limitFixed",
    "init",
    "limit",
    "page"
  ],
  async created() {
    // fetch causal list
    this.causalList();
  },
  watch: {
    selectedItem(val) {
      if (val) {
        this.btnState = null;
      }
    },
    date(val) {
      if (val) {
        this.showPDF = false;
      }
    },
    dropdown(val) {
      if (val === "SI") {
        this.dropdownCausal = [];
      }
    },
    page: function (val) {
      this.limit = this.limitFixed * val;
      this.init = this.limit - this.limitFixed;
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY hh:mm A");
      }
    },
    formatUnixDate: function (value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY hh:mm A");
      }
    },
    formatOnlyDate: function (value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY");
      }
    },
    formatOnlyHour: function (value) {
      if (value) {
        return moment.unix(value).format("hh:mm A");
      }
    },
  },
  computed: {
    ...mapState({
      profileData: (state) => state.profileSilver.profileData,
    }),
    likesAllEfectiveAgArray() {
      return this.dropdown.length === this.efectiveAgArray.length;
    },

    iconEfectiveAgArray() {
      if (this.likesAllEfectiveAgArray) return "mdi-close-box";
      if (!this.likesAllEfectiveAgArray) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    iconCausalArray() {
      if (this.likesAllCausalArray) return "mdi-close-box";
      if (!this.likesAllCausalArray) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    likesAllCausalArray() {
      return this.dropdownCausal.length === this.causalArray.length;
    },

    appointmentsByDate() {
      return this.appointments
        .map((a) => {
          try {
            let timezone = this.environmentData.defaultSheet?.timezone;
            if (
              a.startAt &&
              typeof a.startAt == "string" &&
              a.startAt.substr(-7) == "00+0000"
            ) {
              a.startAt = moment(a.startAt).zone(timezone);
            }
            return a;
          } catch (error) {
            console.log(error);
            return a;
          }
        })
        .slice()
        .sort((a, b) => {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          if (a.startAt || b.startAt) {
            if (a.startAt > b.startAt) {
              return 1;
            }
            if (a.startAt < b.startAt) {
              return -1;
            }
          }
          // a must be equal to b
          return 0;
        })
        .filter((element, index)=>{
          if((index + 1) >= this.init && (index + 1) <= this.limit){
            return element;
          }
        })        
    },
  },
  methods: {
    ...mapActions(["sendExtraFields", "fetchCauselList"]),
    toggleEfectiveAgArray() {
      this.$nextTick(() => {
        if (this.likesAllEfectiveAgArray) {
          this.dropdown = [];
        } else {
          this.dropdown = this.efectiveAgArray;
        }
      });
    },
    toggleStatusCausalArray() {
      this.$nextTick(() => {
        if (this.likesAllCausalArray) {
          this.dropdownCausal = [];
        } else {
          this.dropdownCausal = this.causalArray;
        }
      });
    },
    getHour(date) {
      const formatDate = new Date(date).toLocaleString("es-CO", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return formatDate;
    },
    getDate(date) {
      return moment(date).format("DD/MM/YYYY h:m a");
    },
    getDifference(start, end) {
      return moment(end).diff(moment(start), "minutes") + " Minutos";
    },
    getMyEps(point) {
      return (
        this.environmentData.points?.find((entity) => {
          return entity.cost_center === point;
        }) || ""
      );
    },
    getEps(eps) {
      return (
        this.environmentData.entities?.find((entity) => {
          return entity.code === eps;
        }) || ""
      );
    },
    toArrayForm(formStr) {
      return formStr.split(";");
    },
    changePDFClass() {
      this.changePDFClassState = !this.changePDFClassState;
    },
    handleSelectItem(id, item) {
      const $item_visors = document.querySelectorAll(
        `.VBC${id} .item_visor_${item.id}`
      );
      const $allToRemove = document.querySelectorAll(
        ".VisorGrid__Item--active"
      );

      this.showPDF = true;
      this.selectedItem = item;
      this.dropdown = null;
      this.dropdownCausal = [];

      $allToRemove.forEach(($alr) => {
        $alr.classList.remove("VisorGrid__Item--active");
      });

      $item_visors.forEach(($item_visor) => {
        $item_visor.classList.add("VisorGrid__Item--active");
      });
      // this.selectAppointment(item);

      // autofull new fields
      if (this.selectedItem.actions) {
        let extraFields = this.selectedItem.actions.filter(
          (a) => a.action == "Silver: ExtraFields"
        );
        if (extraFields.length > 0) {
          this.dropdown = extraFields[0].efective;
          this.dropdownCausal =
            extraFields[0].efective == "NO"
              ? JSON.parse(extraFields[0].reason)
              : [];
        }
      }

      this.causalList();
    },
    copyText(id) {
      /* Get the text field */
      let copyText = document.getElementById(id);
      let textArea = document.createElement("textarea");

      copyText.style.color = "#00D100";
      textArea.value = copyText.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      textArea.remove();

      const timer = setTimeout(() => {
        copyText.style.color = "black";
        clearTimeout(timer);
      }, 1000);
    },
    handleClickFormBtn(btn, appointment) {
      if (btn == "9999999999999") return;
      console.log(btn);
      this.existPdf = false;
      this.loading = true;
      this.btnState = btn;
      this.searchS3(btn, appointment);
    },

    async searchS3(formula, appointment) {
      this.loading = true;
      this.items = [];
      this.src = "";
      AWS.config.region = "us-east-1"; // 1. The region
      const save_key = process.env.VUE_APP_AWS_POLLING_API_KEY;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: save_key, // 2. The identity pool
      });

      AWS.config.credentials.get(function (err) {
        if (err) alert(err);
      });

      // initia s3
      s3 = new AWS.S3();
      bucketName = "cafam-prod-appointments";

      await new Promise((resolve) => {
        this.checkProperties(formula, resolve, appointment);
      });

      if (!this.existPdf) {
        this.loading = false;
      }

      console.log(this.items);
    },

    checkProperties(formula, resolve, item) {
      let prefix = `${formula}/${item.code}/`;

      var params = {
        Bucket: bucketName,
        Delimiter: "",
        Prefix: prefix,
      };

      let vue = this;

      s3.listObjects(params, async function (err, data) {
        if (err) throw err;
        // var href = this.request.httpRequest.endpoint.href;
        // var bucketUrl = href + bucketName + "/";

        console.log(data);
        if (data.Contents.length > 0) {
          vue.showVideo = true;
        } else {
          // stop loading
          vue.loading = false;
        }

        var documents = data.Contents.map(function (video) {
          //   var videoUrl = bucketUrl + encodeURIComponent(video.Key);
          return {
            name: video.Key,
            formula: formula,
            value: vue.signObject(video.Key),
            LastModified: moment(video.LastModified).format(
              "YYYY/MM/DD hh:mm A"
            ),
          };
        });

        documents
          .map(async function (v) {
            console.log(v);
            // is object
            if (v.name) {
              if (v.name.split(".")[1] != "pdf") {
                await axios.get(v.value).then((response) => {
                  v.value = response.data;
                });

                resolve();
              } else {
                vue.existPdf = true;
                resolve();
              }

              // is array
            } else {
              let pictureIndex = 0;
              await vue.checkPicture(pictureIndex, v);

              resolve();
            }
          })
          .sort(function (a, b) {
            return (
              moment(b.LastModified).toDate() - moment(a.LastModified).toDate()
            );
          });

        console.log(documents);

        vue.items.push(documents);
      });
    },

    async checkPicture(pictureIndex, v) {
      /* eslint-disable */
    return new Promise(async resolve=>{
      file = v[pictureIndex];
      console.log(file)
      if(file && file.name){
        if(file.name.split('.')[1] != 'pdf'){
          await axios.get(file.value).then((response) => {
            file.value = response.data;
          });
          // end general loop
          if((index+1) == formulas.length){
            resolve();
          }else{
            vue.checkPicture(pictureIndex+1, v);
          }
        }else{
          this.existPdf = true;
          // end general loop
          if((index+1) == formulas.length){
            resolve();
          }else{
            vue.checkPicture(pictureIndex+1, v);
          }
        }
      }
    })
      /* eslint-enable */
    },

    handleFullscreen(id) {
      const $elem = document.getElementById(id);
      this.openFullscreen($elem);
    },

    openFullscreen(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },

    clear() {
      this.items = [];
      // this.appointments = [];
    },

    async download(key) {
      console.log(key);
      //   const url = this.signObject(key);
      // window.open(key.value, "_blank");

      axios({
        url: key.value,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", key.name);
        document.body.appendChild(link);
        link.click();
      });
    },

    signObject(key) {
      const signedUrlExpireSeconds = 60 * 30; // your expiry time in seconds.
      return s3.getSignedUrl("getObject", {
        Bucket: bucketName,
        Key: key,
        Expires: signedUrlExpireSeconds,
      });
    },

    getPdfLoaded() {
      this.loading = false;
    },

    getPagesPdf(event, id) {
      this.numPages[id] = event;
      this.$forceUpdate();
      this.$nextTick();
    },

    sendCancelAppointment(appointment, action) {
      this.$emit("cancelEvent", appointment, action);
    },

    async saveExtraFields(efective) {
      this.$emit("preload", true);
      this.loading = true;

      if (efective == "SI") {
        // Send data action
        await this.sendExtraFields({
          document: `(${this.profileData.user.id}) ${this.profileData.user.firstName} ${this.profileData.user.lastName}`,
          timestamp_click: moment().toDate().getTime(),
          appointment_code: this.selectedItem.code,
          action: "Silver: ExtraFields",
          origin_url: window.location.href,
          efective: efective,
          reason: "",
        });
        this.$emit("searchAppointments");
        this.$emit("preload", false);
        this.loading = false;
        this.snackbar = true;
        this.text = "Datos guardados";
      } else {
        if (
          this.dropdownCausal &&
          typeof this.dropdownCausal == "object" &&
          this.dropdownCausal.length > 0
        ) {
          // avoid double sending
          this.sendData = 0;
          clearInterval(this.sending);
          this.sending = setInterval(async () => {
            this.sendData++;
            console.log(this.sendData);
            if (this.sendData == 3) {
              clearInterval(this.sending);

              // Send data action
              await this.sendExtraFields({
                document: `(${this.profileData.user.id}) ${this.profileData.user.firstName} ${this.profileData.user.lastName}`,
                timestamp_click: moment().toDate().getTime(),
                appointment_code: this.selectedItem.code,
                action: "Silver: ExtraFields",
                origin_url: window.location.href,
                efective: this.dropdown,
                reason: JSON.stringify(this.dropdownCausal),
              });
              this.$emit("searchAppointments");
              this.$emit("preload", false);
              this.loading = false;

              this.snackbar = true;
              this.text = "Datos guardados";
            }
          }, 1000);
        } else {
          alert("Debes escoger una causa");
          this.$emit("preload", false);
          this.loading = false;
        } 
      }
    },

    async causalList() {
      let list = await this.fetchCauselList();
      this.causalArray = list.data;
    },

    availableDate(date) {
      return moment(date) > moment()
    }
  },
};
</script>

<style scoped>
.VisorDate {
  display: flex;
  /* margin-top: -24px; */
}

.VisorGrid {
  display: grid;
  grid-template-columns: 1fr;
  /* overflow: auto; */
  height: fit-content;
  position: relative;
  overflow-x: auto;
}

.VisorPdfGrid {
  /* margin-right: 600px; */
  /* max-width: 600px; */
  max-width: 100%;
  flex: 2;
  transition: 0.5s all;
  overflow: auto;
}

.block {
  display: block;
  width: 100%;
}

.VisorGrid__Header {
  display: grid;
  grid-template-columns: repeat(23, minmax(150px, 1fr));
  justify-items: center;
  align-items: center;
  background-color: #f1ecec;
  border: 1px solid #c3bcbc;
}

.BodyG {
  overflow: auto;
  max-height: calc(100vh - 325px);
  overflow-x: hidden;
}

.VisorGrid__Body {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(23, minmax(150px, 1fr));
  border: 1px solid #c3bcbc;
  font-size: 12px;
  justify-content: center;
}

.VisorGrid__Body:hover > .VisorGrid__Item {
  background-color: #d4d4d4;
}

.VisorGrid__Item--active {
  background-color: #d4d4d4 !important;
}

.VisorGrid__Item {
  background-color: #f1ecec;
  border-right: 1px solid #c3bcbc;
  cursor: pointer;
  transition: 0.5s background-color;
  position: relative;
}

.Close {
  position: absolute;
  right: 5px;
  top: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Close button {
  display: block;
  margin: 0.2rem 0;
}

.VisorGrid__Item span {
  padding: 0.5rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.active--item {
  background-color: rgb(247, 247, 247);
  border: none;
  border-right: none;
}

.VisorPdf {
  flex: 1;
  position: relative;
  background-color: whitesmoke;
  text-align: center;
  transition: 0.5s all;
  max-height: calc(100vh - 274px);
  overflow: auto;
  min-width: 400px;
}

.ButtonPDF {
  position: absolute;
  left: 5px;
  top: 5px;
  font-weight: bold;
  color: black;
  font-size: 2rem;
  height: 11px;
  display: flex;
}

.VisorPdfExpanded {
  flex: 4;
}

.ContainerText {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  margin-top: 10px;
  justify-content: center;
  font-weight: bold;
}

.btnIcon {
  margin: 0.2rem;
}

.btnIcon i {
  font-size: 2rem;
}

.AllWidth {
  width: 100%;
  margin-bottom: 8px;
}

.marginToAllwidth {
  margin: 5px;
}

.AllWidth span,
.marginToAllwidth span,
.ContainerText i {
  margin: 0 5px;
}

.ContainerText button {
  margin: 5px 0;
}

.flex-dk {
  display: flex;
  justify-content: flex-end;
}

.btnStateC {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* background-color: gray; */
  border-radius: 5px;
  position: relative;
  /* padding: 1rem; */
}

.px-2 {
  padding: 0 2rem;
}

.ItemSeparete {
  margin: 1rem 0;
  background-color: white;
}

.dkShee {
  background: none;
}

.Dropdowns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.AgendamientoEfectivo,
.Causal {
  margin: 0rem 4rem;
  position: relative;
  max-width: 241px;
}

/* .MaxContent {
  max-width: 152.23px;
} */

.button-tape {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 51;
  /* height: 36px; */
}

.relative {
  position: relative;
  margin: 0 auto;
}

.DropdownItem {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 50;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
}

.rotateArrowClass {
  transition: 1s all;
  animation: 1s ease-in-out forwards rotateArrow;
}

.arrowClass {
  transition: 1s all;
  animation: 1s ease-in-out forwards rotateArrow reverse;
}

.DropTape {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 51;
}

.VisorGrid__Item span.tooltip-box {
  color: black;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 10px rgb(0 0 0 / 38%);
  padding: 5px;
  border-radius: 5px;
  z-index: 900;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  display: none !important;
  visibility: hidden;
  transition: 0.1s all ease-in-out;
  animation: showTooltip 0.1s ease-in-out forwards;
  animation-delay: 0.8s;
  transition-delay: 0.8s;
  width: auto;
}

.VisorGrid__Item:hover span.tooltip-box,
.VisorGrid__Item span.tooltip-box:hover {
  display: flex !important;
  justify-content: start;
  align-items: center;
}

.VisorGrid__Item span.tooltip-box span {
  padding: 0;
  width: auto;
  overflow: hidden;
  text-overflow: clip;
  display: contents;
}

span.copyBtn {
  width: auto;
  margin-right: 5px;
}

@keyframes showTooltip {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes rotateArrow {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
</style>
