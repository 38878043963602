<template>
  <div class="text-center">
    <v-dialog v-model="showGluedDetailModal" width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <p class="TittleCancelModal">
            {{ action == "cancel" ? "Cancelar" : "Restaurar" }} Cita
          </p>
        </v-card-title>

        <v-card-text>
          <h5 class="TextModalCancel">
            ¿Realmente desea
            {{ action == "cancel" ? "cancelar" : "restaurar" }} la cita?
          </h5>
        </v-card-text>

        <v-card-actions class="modal-footer">
          <v-btn
            :loading="loading"
            color="sucess"
            :class="action == 'cancel' ? 'red text-white' : 'blue text-white'"
            @click="cancel"
          >
            {{ action == "cancel" ? "Cancelar" : "Restaurar" }}
          </v-btn>
          <v-btn color="sucess" class="btn-green" @click="close">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  name: "GluedDetail",
  props: {},
  data() {
    return {
      showGluedDetailModal: false,
      sortKey: "",
      searchQuery: "",
      columns: [],
      gridData: [],
      filteredData: [],
      appointment: {},
      loading: false,
      snackbar: false,
      text: "",
      action: "cancel", // cancel or restart
    };
  },
  computed: {
    ...mapState({
      queued: (state) => state.queuedSilver.queuedData,
      queuedLoading: (state) => state.queuedSilver.loading,
      env: (state) => state.environmentSilver.environment.env,
      responseAppointment: (state) =>
        state.appointmentSilver.responseAppointment,
      profileData: (state) => state.profileSilver.profileData,
    }),
  },
  methods: {
    ...mapActions([
      "fetchQueuedDetails",
      "resetQueued",
      "updateAppointment",
      "setProcessing",
    ]),
    open(appointment, action) {
      this.appointment = appointment;
      this.showGluedDetailModal = true;
      this.action = action;
    },
    async close() {
      this.showGluedDetailModal = false;
    },
    async cancel() {
      let data = {
        branch: this.appointment.branch,
        status: this.action == "cancel" ? "CANCELED" : "CONFIRMED",
        schedule: this.appointment.schedule,
        code: this.appointment.code,
        startAt: moment(this.appointment.startAt).format().replace("-05:00", "-0500"),
        endAt: moment(this.appointment.endAt).format().replace("-05:00", "-0500"),
      };

      this.loading = true;
      await this.updateAppointment({
        url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
        headers: {
          token_appointments: this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
        },
        queryData: "",
        data: data,
      });

      if (this.responseAppointment.status == "CANCELED") {
        // Send data action
        await this.setProcessing({
          document: `(${this.profileData.user.id}) ${this.profileData.user.firstName} ${this.profileData.user.lastName}`,
          timestamp_click: moment().toDate().getTime(),
          appointment_code: data.code,
          action: "Silver: Cancelación",
        });

        this.snackbar = true;
        this.text = "La cita se ha cancelado correctamente";
      } else {
        this.snackbar = true;
        this.text = "La cita se ha reestablecido correctamente";
      }

      this.loading = false;
      this.close();
      this.$emit("searchAppointments");
    },
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  watch: {},
};
</script>
<style scoped>
p.TittleCancelModal {
  margin: 0;
  margin-bottom: 0;
}

.TextModalCancel {
  margin-top: 16px;
  font-weight: normal;
}

.text-white {
  color: white;
}
</style>
