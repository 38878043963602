<template>
  <v-card>
    <v-toolbar flat>
      <div class="flexDK">
        <v-toolbar-title>Agendamiento</v-toolbar-title>
        <v-btn
          color="primary"
          style="padding: 0; margin-left: 2rem; width: 108px"
          @click="search"
          :loading="loadingAppointments"
        >
          Buscar
        </v-btn>

        <v-btn
          color="gray"
          style="padding: 0; margin-left: 2rem; width: 108px"
          @click="clear"
        >
          Limpiar
        </v-btn>
        <v-btn
          color="gray"
          style="padding: 0; margin-left: 2rem; width: 108px"
          @click="exportData"
        >
          Descargar
        </v-btn>
      </div>
    </v-toolbar>

    <v-col cols="6" md="4">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="showModalExport"
      >
        <template>
          <v-card>
            <v-toolbar color="primary" dark
              >Selecciona el rango de fechas para exportar</v-toolbar
            >
            <v-card-text>
              <v-dialog
                ref="dialogExport"
                v-model="modalExportData"
                :return-value.sync="dateExport"
                persistent
                width="290px"
                class="inputDK"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeExportText"
                    label=""
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>

                <template v-slot:selection="{ data }">
                  <v-chip>
                    <span>{{ data }} </span>
                  </v-chip>
                </template>

                <v-date-picker v-model="dateExport" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalExportData = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogExport.save(dateExport)"
                  >
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" dark @click="exportData" :loading="loading"
                >Descargar</v-btn
              >
              <v-btn text @click="showModalExport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>

    <v-card-text class="white lighten-4 VCardText">
      <v-row no-gutters>
        <v-col cols="6" sm="6" md="6" class="VcolDK">
          <v-dialog
            ref="dialogInit"
            v-model="modalInit"
            :return-value.sync="date"
            persistent
            width="290px"
            class="inputDK"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Rango de fechas"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <template v-slot:selection="{ data }">
              <v-chip>
                <span>{{ data }} </span>
              </v-chip>
            </template>

            <v-date-picker v-model="date" range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalInit = false">
                Cancelar
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialogInit.save(date)">
                Aceptar
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="VcolDK">
          <v-text-field
            v-model="dni"
            label="DNI"
            class="inputDK"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="VcolDK">
          <v-text-field
            v-model="email"
            label="email"
            class="inputDK"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="VcolDK">
          <v-select
            class="inputDK"
            v-model="searchPoint"
            :items="pointsFilter"
            item-text="name"
            item-value="cost_center"
            :menu-props="{ maxHeight: '400' }"
            label="Punto(selección múltiple)"
            multiple
            persistent-hint
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggle">
                <v-list-item-action>
                  <v-icon
                    :color="searchPoint.length > 0 ? 'indigo darken-4' : ''"
                  >
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Seleccionar Todo </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <!-- Add a tile with Select All as Lalbel and binded on a method that add or remove all items -->
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0 && searchPoint.length == 1">
                <span>{{ item.name }} </span>
              </v-chip>
              <span
                v-if="index === 0 && pointsFilter.length == searchPoint.length"
              >
                <span>Seleccionadas: todas </span>
              </span>
              <span
                v-if="
                  index === 0 &&
                  searchPoint.length > 1 &&
                  pointsFilter.length != searchPoint.length
                "
              >
                <span>Seleccionadas: {{ searchPoint.length }} </span>
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="VcolDK">
          <v-select
            class="inputDK"
            v-model="states"
            :items="statesList"
            :menu-props="{ maxHeight: '400' }"
            label="Estado(selección múltiple)"
            multiple
            persistent-hint
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggleStatus">
                <v-list-item-action>
                  <v-icon :color="states.length > 0 ? 'indigo darken-4' : ''">
                    {{ iconStatus }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Seleccionar Todo </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <!-- Add a tile with Select All as Lalbel and binded on a method that add or remove all items -->
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0 && states.length == 1">
                <span>{{ item.name }} </span>
              </v-chip>
              <span v-if="index === 0 && states.length == states.length">
                <span>Seleccionadas: todas </span>
              </span>
              <span
                v-if="
                  index === 0 &&
                  states.length > 1 &&
                  states.length != states.length
                "
              >
                <span>Seleccionadas: {{ states.length }} </span>
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>

    <v-progress-linear
      v-if="loading"
      indeterminate
      value="15"
    ></v-progress-linear>

    <visor-dates
      v-if="appointments.length"
      :appointments="appointments"
      :nameSelected="nameSelected"
      :selectAppointment="selectAppointment"
      :environmentData="environmentData"
      :date="date"
      @cancelEvent="cancelAppointment"
      @preload="preload"
      @searchAppointments="search"
      :pagination="pagination"
      :initFixed="initFixed"
      :limitFixed="limitFixed"
      :init="init"
      :limit="limit"
      :page="page"
    >
      <v-expand-transition>
        <div>
          <v-card-text
            class="grey lighten-4"
            v-for="(item, index) in items"
            :key="index"
          >
            <v-sheet max-width="890" height="498" class="mx-auto">
              <img
                :src="item.value"
                alt="Image"
                v-if="item.name.split('.')[1] != 'pdf'"
              />
              <embed
                v-else
                :src="item.value"
                width="500"
                height="375"
                type="application/pdf"
              />
              <a @click="download(item)">Descargar</a>
            </v-sheet>
          </v-card-text>
        </div>
      </v-expand-transition>
    </visor-dates>
    <p v-if="!loading && !appointments.length" style="padding: 1rem">
      No hay resultados
    </p>
    <div class="text-center" v-show="appointments.length > 1">
      <v-pagination
        v-model="page"
        :length="Math.ceil(appointments.length / this.pagination)"
      ></v-pagination>
    </div>
    <cancel-appointments
      ref="cancelAppointments"
      @searchAppointments="search"
    ></cancel-appointments>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import axios from "axios";

import VisorDates from "../components/VisorDates.vue";
import CancelAppointments from "../components/appointments/CancelAppointments.vue";
import zipcelx from "zipcelx";

var AWS = require("aws-sdk");
let s3, bucketName;
moment.locale("es");

export default {
  name: "Appointments",
  data: () => ({
    sticky: false,
    state: "",
    states: [],
    dni: "",
    email: "",
    searching: false,
    statesList: [
      { value: "TO_BE_CONFIRMED", text: "A confirmar" },
      { value: "CONFIRMED", text: "Confirmada" },
      { value: "CANCELED", text: "Cancelada por cliente" },
      { value: "CANCELED", text: "Cancelada internamente" },
      {
        value: "EXPIRED_CONFIRMATION_TIME",
        text: "Tiempo de confirmación expirado",
      },
      { value: "CHECKED_IN", text: "Check In confirmado" },
      { value: "ABSENT", text: "Ausente" },
    ],
    showVideo: false,
    modalInfoVideo: null,
    items: [],
    searchRecorder: "",
    loading: false,
    searchSucursal: "",
    searchWorkStation: "",
    searchEndDate: "",
    searchAsesor: "",
    searchClient: "",
    searchInitDate: "",
    searchTurn: "",
    searchPoint: "",
    year: "",
    month: "",
    page: 1,
    src: "",
    day: null,

    filterItems: [],
    nameSelected: "",

    pageOfItems: [],

    pagination: 100,
    initFixed: 1,
    limitFixed: 100,
    init: 1,
    limit: 100,
    days: [],

    // datepicker
    date: [
      moment().format("YYYY-MM-DD"),
      moment().add(1, "days").format("YYYY-MM-DD"),
    ],
    date_init: moment().format("YYYY-MM-DD"),
    date_end: moment().add(1, "days").format("YYYY-MM-DD"),
    modalInit: false,
    modalEnd: false,

    // export
    modalExportData: false,
    showModalExport: false,
    dateExport: [
      moment().format("YYYY-MM-DD"),
      moment().add(1, "days").format("YYYY-MM-DD"),
    ],
    date_init_export: moment().format("YYYY-MM-DD"),
    date_end_export: moment().add(1, "days").format("YYYY-MM-DD"),
  }),
  filter: "formatDate",
  function(value) {
    if (value) {
      return moment(String(value)).format("MM/DD/YYYY hh:mm");
    }
  },
  components: {
    VisorDates,
    CancelAppointments,
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapState({
      profileData: (state) => state.profileSilver.profileData,
      branches: (state) => state.branchesSilver.branchesData,
      branchesInfoCompany: (state) => {
        return state.profileSilver.branchesInfoCompany;
      },
      branchesInfoCompanySort: (state) => {
        return state.profileSilver.branchesInfoCompany?.sort();
      },
      daysMonth() {
        let maxDay = new Date(this.year, this.month, 0).getDate();
        return [...Array(maxDay).keys()].map((i) => {
          let day = i + 1;
          day = day > 9 ? day.toString() : "0" + day;
          return day;
        });
      },
      environmentData: (state) => state.environmentSilver.environment,
      selectedBranch: (state) => state.branchesSilver.selectedBranch,
      advisors: (state) => {
        let users = state.loggedUserSilver.users;
        return users.map((u) => {
          return {
            name: `${u.uUser.firstName} ${u.uUser.lastName}`,
            id: u.id,
          };
        });
      },

      appointments: (state) => state.appointmentSilver.appointments,
      appointmentsExport: (state) => state.appointmentSilver.appointmentsExport,
      loadingAppointments: (state) =>
        state.appointmentSilver.loadingAppointments,
      points: (state) => state.environmentSilver.environment.points,
      mulTibranches: (state) => state.branchesSilver.selectedMultiBranches,
    }),
    pointsFilter() {
      if (!this.points) return [];
      if (this.branches) {
        /* eslint-disable */
        if(this.branches.defaultBranch){
          let filterBranched = this.points.filter(b=>b.branch_original_id == this.selectedBranch.id)
          this.searchPoint = filterBranched[0].cost_center ? [filterBranched[0].cost_center] : [];
        }
        /* eslint-enable */
        if (this.branches.branches)
          return this.points.filter((p) =>
            this.branches.branches
              .map((b) => b.id)
              .includes(p.branch_original_id)
          );
        else
          return this.points.filter(
            (p) => p.branch_original_id == this.selectedBranch.id
          );
      } else {
        return this.points.filter(
          (p) => p.branch_original_id == this.selectedBranch.id
        );
      }
    },
    // filterItems() {
    //   this.searchRecorder;
    //   const filteredRenponse = this.items.filter((item) => {
    //     return item.name
    //       .toLowerCase()
    //       .includes(this.searchRecorder.toLowerCase());
    //   });
    //   return filteredRenponse;
    // },
    likesAllPoints() {
      return this.searchPoint.length === this.pointsFilter.length;
    },
    likesAllStatuses() {
      return this.states.length === this.statesList.length;
    },
    likesSomeFruit() {
      return this.searchPoint.length > 0 && !this.likesAllPoints;
    },
    icon() {
      if (this.likesAllPoints) return "mdi-close-box";
      if (!this.likesAllPoints) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    iconStatus() {
      if (this.likesAllStatuses) return "mdi-close-box";
      if (!this.likesAllPoints) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    dateRangeText() {
      /* eslint-disable */
      let init = this.date[0];
      let end = this.date[1];
      if(end){
        if(init<end){
          this.date_init = init;
          this.date_end = end;
          return `Del ${init} al ${end} `;
        } else {
          this.date_init = end;
          this.date_end = init;
          return `Del ${end} al ${init} `;
        } 
      } else {
        this.date_init = init;
        this.date_end = null;
        return init;
      } 

      /* eslint-enable */
    },
    dateRangeExportText() {
      /* eslint-disable */
      let init = this.dateExport[0];
      let end = this.dateExport[1];
      if(end){
        if(init<end){
          this.date_init_export = init;
          this.date_end_export = end;
          return `Del ${init} al ${end} `;
        } else {
          this.date_init_export = end;
          this.date_end_export = init;
          return `Del ${end} al ${init} `;
        } 
      } else {
        this.date_init = init;
        this.date_end = null;
        return init;
      } 

      /* eslint-enable */
    },
  },
  watch: {
    page: function (val) {
      this.limit = this.limitFixed * val;
      this.init = this.limit - this.limitFixed;
    },
  },
  created() {
    var currentTime = new Date();
    this.year = currentTime.getFullYear();
    let month = currentTime.getMonth() + 1;
    this.month = month > 9 ? month : "0" + month;
    this.getThemeMode.verticalSidebarDrawer = false;

    // enable change work station
    this.$root.enableChangeWorkStation = true;
  },
  methods: {
    ...mapActions([
      "checkToken",
      "handleUsers",
      "fetchSchedule",
      "fetchAppointments",
      "clearAppointments",
      "fetchExportAppointments",
    ]),
    selectAppointment(appointment) {
      this.searchS3(appointment);
      // this.showVideo = true;
    },
    selectVideo(item) {
      const url = this.signObject(item);

      this.src = url;
      this.showVideo = true;
      this.modalInfoVideo = item;
      this.nameSelected = item.name;
    },
    closeModalVideo() {
      this.modalInfoVideo = null;
      this.showVideo = false;
      this.src = "";
    },
    toDate(unix_timestamp) {
      return moment
        .unix(unix_timestamp.slice(0, -3))
        .format("YYYY/MM/DD hh:mm A");
    },
    getBranch(i) {
      if (this.branches) {
        let b = this.branches.branches.filter((b) => b.id == i);
        return b.length > 0 ? b[0] : {};
      } else return {};
    },
    getWorkStation(i) {
      if (this.branches) {
        let ws = this.branchesInfoCompany.filter((w) => w.id == i);
        return ws.length > 0 ? ws[0] : {};
      } else return {};
    },
    async search() {
      this.loading = true;
      this.searching = false;
      this.page = 1;
      this.clearAppointments();
      let queryData = {};
      if (this.date_init && this.date_end) {
        queryData.fromDate = `${moment(this.date_init).format(
          "YYYY-MM-DD"
        )}T00:00:00-0500`;
        queryData.toDate = `${moment(this.date_end)
          // .add(1, "days")
          .format("YYYY-MM-DD")}T00:00:00-0500`;
      } else {
        queryData.day = `${moment(this.date_init).format(
          "YYYY-MM-DD"
        )}T00:00:00-0500`;
      }

      queryData.dni = this.dni.replaceAll(/\s/g, "");
      queryData.email = this.email.replaceAll(/\s/g, "");
      queryData.status = this.states ? this.states.join(",") : "";

      if (this.searchPoint) {
        queryData.searchPoint = this.searchPoint;
      }

      if (this.searchPoint.length < 1)
        this.searchPoint = this.pointsFilter.map((p) => p.cost_center);
      queryData.branchId = JSON.stringify([
        ...new Set(
          this.searchPoint
            .map((s) => this.points.find((p) => p.cost_center == s))
            .map((p) => p.branch_id)
        ),
      ]);

      console.log(queryData);

      await this.fetchAppointments(queryData);
      this.searching = true;
      this.loading = false;
    },
    async searchS3(item) {
      this.loading = true;
      this.items = [];
      this.src = "";
      AWS.config.region = "us-east-1"; // 1. The region
      const save_key = process.env.VUE_APP_AWS_POLLING_API_KEY;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: save_key, // 2. The identity pool
      });

      if (this.profileData.user?.company) {
        AWS.config.credentials.get(function (err) {
          if (err) alert(err);
        });
      }

      // initia s3
      s3 = new AWS.S3();
      bucketName = "cafam-prod-appointments";

      //
      // let items = [];

      //  Check fornmulas
      let formulas = item.reason.formulas.split(";");
      let generalIndex = 0;

      await new Promise((resolve) => {
        this.checkProperties(formulas, generalIndex, resolve, item);
      });

      this.loading = false;
      console.log(this.items);
    },

    checkProperties(formulas, index, resolve, item) {
      const formula = formulas[index];

      let prefix = `${formula}/${item.code}/`;

      var params = {
        Bucket: bucketName,
        Delimiter: "",
        Prefix: prefix,
      };

      let vue = this;

      s3.listObjects(params, async function (err, data) {
        if (err) throw err;
        // var href = this.request.httpRequest.endpoint.href;
        // var bucketUrl = href + bucketName + "/";

        if (data.Contents.length > 0) {
          vue.showVideo = true;
        }

        var videos = data.Contents.map(function (video) {
          //   var videoUrl = bucketUrl + encodeURIComponent(video.Key);
          return {
            name: video.Key,
            value: vue.signObject(video.Key),
            LastModified: moment(video.LastModified).format(
              "YYYY/MM/DD hh:mm A"
            ),
          };
        });

        videos
          .map(async function (v) {
            if (v.name) {
              if (v.name.split(".")[1] != "pdf") {
                await axios.get(v.value).then((response) => {
                  v.value = response.data;
                });

                // end general loop
                if (index + 1 == formulas.length) {
                  resolve();
                } else {
                  vue.checkProperties(formulas, index + 1, resolve, item);
                }
              } else {
                // end general loop
                if (index + 1 == formulas.length) {
                  resolve();
                } else {
                  vue.checkProperties(formulas, index + 1, resolve, item);
                }
              }
            } else {
              v.map(async function (file) {
                console.log(file);
                if (file && file.name) {
                  if (file.name.split(".")[1] != "pdf") {
                    await axios.get(file.value).then((response) => {
                      file.value = response.data;
                    });
                    // end general loop
                    if (index + 1 == formulas.length) {
                      resolve();
                    } else {
                      vue.checkProperties(formulas, index + 1, resolve, item);
                    }
                  } else {
                    // end general loop
                    if (index + 1 == formulas.length) {
                      resolve();
                    } else {
                      vue.checkProperties(formulas, index + 1, resolve, item);
                    }
                  }
                }
              });
            }
          })
          .sort(function (a, b) {
            return (
              moment(b.LastModified).toDate() - moment(a.LastModified).toDate()
            );
          });

        vue.items.push(videos);
      });
    },

    clear() {
      this.items = [];
      this.clearAppointments();
      this.state = "";
      this.states = [];
      this.dni = "";
      this.email = "";
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.searching = false;
    },

    download(key) {
      console.log(key);
      //   const url = this.signObject(key);
      window.open(key.value, "_blank");
    },

    async exportData() {
      this.loading = true;
      this.searching = true;
      let queryData = {};
      if (this.date_init && this.date_end) {
        queryData.fromDate = `${moment(this.date_init).format(
          "YYYY-MM-DD"
        )}T00:00:00-0500`;
        queryData.toDate = `${moment(this.date_end)
          // .add(1, "days")
          .format("YYYY-MM-DD")}T00:00:00-0500`;
      } else {
        queryData.day = `${moment(this.date_init).format(
          "YYYY-MM-DD"
        )}T00:00:00-0500`;
      }

      queryData.dni = this.dni.replaceAll(/\s/g, "");
      queryData.email = this.email.replaceAll(/\s/g, "");
      queryData.status = this.states ? this.states.join(",") : "";
      queryData.timezone = this.environmentData.defaultSheet?.timezone;
      if (this.searchPoint.length < 1)
        this.searchPoint = this.pointsFilter.map((p) => p.cost_center);
      queryData.branchId = JSON.stringify([
        ...new Set(
          this.searchPoint
            .map((s) => this.points.find((p) => p.cost_center == s))
            .map((p) => p.branch_id)
        ),
      ]);

      await this.fetchExportAppointments(queryData);
      let appointmentsExport;

      // time formate
      appointmentsExport = this.appointmentsExport.map((a) => {
        if (
          a[22] != 'Hora Autorizacion de Datos'
        ) {
          a[22] = moment.unix(a[22]).format("hh:mm A")
        }
        return a;
      });

      // filter cecos
      if (this.searchPoint) {
        appointmentsExport = this.appointmentsExport.filter((a) => {
          if (
            this.searchPoint.includes(a[12]) ||
            a[12] == "Ceco de Agendamiento"
          ) {
            return a;
          }
        });
      }

      // sanitize
      appointmentsExport = appointmentsExport.map((a) => {
        return a.map((d) => {
          return {
            value: d,
            type: "string",
          };
        });
      });

      const config = {
        filename: `citas_${moment(this.date_init).format("YYYY-MM-DD")}${
          this.date_end ? "_" + moment(this.date_end).format("YYYY-MM-DD") : ""
        }`,
        sheet: {
          data: appointmentsExport,
        },
      };

      zipcelx(config);

      this.loading = false;
    },

    signObject(key) {
      const signedUrlExpireSeconds = 60 * 30; // your expiry time in seconds.
      return s3.getSignedUrl("getObject", {
        Bucket: bucketName,
        Key: key,
        Expires: signedUrlExpireSeconds,
      });
    },

    asesorName(item) {
      let name = this.advisors.filter((u) => u.id == item);
      name = name.length > 0 ? name[0].name : "No se encontró";
      return name;
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllPoints) {
          this.searchPoint = [];
        } else {
          this.searchPoint = this.pointsFilter.map((p) => p.cost_center);
        }
      });
    },

    toggleStatus() {
      this.$nextTick(() => {
        if (this.likesAllStatuses) {
          this.states = [];
        } else {
          this.states = this.statesList.map((p) => p.value);
        }
      });
    },

    // cancel appointments
    cancelAppointment(data, action) {
      this.$refs.cancelAppointments.open(data, action);
    },

    preload(load) {
      this.loading = load;
    },
  },
};
</script>

<style lang="scss">
.active {
  background: #bdd6e8;
}
.VideoDK {
  position: relative;
  .button-dk {
    position: absolute;
    top: -20px;
    right: -20px;
  }
}

.VcolDK {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.ModalOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #30353880;
  z-index: 50;
}

.Modal {
  z-index: 100;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 900px;
  border-radius: 5px;
  animation: fade 0.5s forwards;
}

.flexDK {
  display: flex;
}

.inputDK {
  margin: 0 1rem;
}

.VCardText {
  padding-bottom: 0;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 960px) {
  .card-dk {
    width: 100%;
    min-width: 295px;
    margin: auto;
  }
}
</style>
